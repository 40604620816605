<template>
  <div id="defaultId1" class="u20bb7 table_X u68g3Fw">
    <!-- 表格部分 -->
    <div id="g88e05" class="pb20">
      <div id="g2e713" class="u97906 mb10" v-if="tableData.length > 0">
        <!-- 标题 -->
        <div id="g9576c" class="u57fd1">
          <div id="gd4d94" class="u5e22d detail_title_20">Ownership</div>
          <div id="ge4e89" class="u41151">
            <div id="g8b81a" @click="activeIcon = 'table1'" :style="activeIcon == 'table1'
              ? 'background-color:#fff;'
              : 'background-color:#F5F5F5'
              " class="ub7903">
              <svg id="idex5xpV" class="icon" t="1718690711396" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="4563" width="2.57em" height="2.57em">
                <path id="id3aCJoQ"
                  d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                  :fill="activeIcon == 'table1' ? '#1290C9' : '#8497AB'" p-id="4564"></path>
              </svg>
            </div>
            <div id="g66b8c" @click="powerEcharts(1)" :style="activeIcon == 'computer1'
              ? 'background-color:#fff;'
              : 'background-color:#F5F5F5'
              " class="u72482">
              <svg id="idNxDoHC" class="icon" t="1718690674036" viewBox="0 0 1214 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="4367" width="2.57em" height="2.57em">
                <path id="idT6sPU1"
                  d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                  :fill="activeIcon == 'computer1' ? '#1290C9' : '#8497AB'" p-id="4368"></path>
                <path id="iddq3HRE"
                  d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                  :fill="activeIcon == 'computer1' ? '#1290C9' : '#8497AB'" p-id="4369"></path>
              </svg>
            </div>
          </div>
        </div>
        <!-- 表格组件 -->
        <div id="g21ab2" class="uM2fl1R" v-if="activeIcon == 'table1'">
          <el-table style="max-height: 800px;overflow-y: auto;" id="idE7SscB" class=" uvnONjL table_height_30 table_font_size_14 table_contentHeight_40" :data="tableData" empty-text="No Data">
            <el-table-column id="idZNiLFn" prop="name" label="Name" show-overflow-tooltip>
              <template slot-scope="scope">
                <span  class=" company_link"   v-if="scope.row.id3a.split('').every(char => char >= '0' && char <= '9')" @click="setid(scope.row)" style="">
                    {{ scope.row.name }}
                  </span>
                <span  v-else class=" "  style="">
                    {{ scope.row.name }}
                  </span>
                
                </template>
            </el-table-column>
            <el-table-column id="idtwl2TQ" prop="country" label="Country" show-overflow-tooltip :width="tableColumnWidth.colum2">
            </el-table-column>
            <el-table-column id="id7GjLfn" prop="location" label="Location" show-overflow-tooltip :width="tableColumnWidth.colum3">
            </el-table-column>
            <el-table-column id="idEZCIbe" prop="type" label="Type" :width="tableColumnWidth.colum4" show-overflow-tooltip>
            </el-table-column>
            <el-table-column id="idJkRfjh" prop="shares" label="Shares(%)" :width="tableColumnWidth.colum5" show-overflow-tooltip align="right">
            </el-table-column>
            <el-table-column id="id9BIvlf" prop="relations" label="Relations" show-overflow-tooltip :width="tableColumnWidth.colum6">
            </el-table-column>
          </el-table>
          <div id="idciPJRI" class="uZgkgLm show_AllHeight" v-if="this.page.total>this.page.pageSize">
            <span id="g5e298" class="u144f9 show_All" @click="ShowAll()">Show All </span>
          </div>
        </div>
        <!-- 图部分 -->
        <div v-show="activeIcon == 'computer1'" class="echartsBtnspiv">
          <div class="echartsBtns">
            <el-button icon="el-icon-plus" :disabled="level >= 3" @click="changeLevel('plus')" />
            <el-button icon="el-icon-minus" :disabled="level <= 1" @click="changeLevel('minus')" />
          </div>
          <div id="g08e9f">
            <div id="idsLwvVN" class="ulDmLg9" alt ref="trendChart"></div>
          </div>
        </div>
      </div>
      <div id="cf7af3" class="u97906 uc1158" v-if="Investment.length > 0">
        <div class="gaosimohu"> </div>
        <!-- 标题 -->
        <div id="cf69b3" class="u57fd1">
          <div id="c9890d" class="u5e22d detail_title_20">Investments</div>
          <div id="c4e07e" class="u41151">
            <div id="c3e402" @click="activeIcon2 = 'table2'" :style="activeIcon2 == 'table2'
              ? 'background-color:#fff;'
              : 'background-color:#F5F5F5'
              " class="ub7903">
              <svg id="c387dd" class="icon u4W5d5U" t="1718690711396" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="4563" width="2.57em" height="2.57em">
                <path id="ced621" class="uQXRB13"
                  d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                  :fill="activeIcon2 == 'table2' ? '#1290C9' : '#8497AB'" p-id="4564"></path>
              </svg>
            </div>
            <div id="c0c20e" @click="powerEcharts(2)"  :style="activeIcon2 == 'computer2'
              ? 'background-color:#fff;'
              : 'background-color:#F5F5F5'
              " class="u72482">
              <svg id="c274da" class="icon upE66RF" t="1718690674036" viewBox="0 0 1214 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="4367" width="2.57em" height="2.57em">
                <path id="c74f10" class="uL7NCen"
                  d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                  :fill="activeIcon2 == 'computer2' ? '#1290C9' : '#8497AB'" p-id="4368"></path>
                <path id="cd9211" class="uRwnrTT"
                  d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                  :fill="activeIcon2 == 'computer2' ? '#1290C9' : '#8497AB'" p-id="4369"></path>
              </svg>
            </div>
          </div>
        </div>
        <!-- 表格组件 -->
        <div id="c612f3" class="uM2fl1R" v-if="activeIcon2 == 'table2'">
          <el-table id="c9929e" style="max-height: 800px;overflow-y: auto;" class="uvnONjL table_height_30 table_font_size_14 table_contentHeight_40" :data="Investment">
            <el-table-column id="c26738" prop="name" label="Name" show-overflow-tooltip>
              <template slot-scope="scope">
                <span  v-if="scope.row.id3a=='-' ||scope.row.type=='Personal'">
                  {{ scope.row.name }}
                </span>
                  <span v-else class=" company_link"  @click="setid(scope.row)" style="">
                    {{ scope.row.name }}
                  </span>
                </template>
            </el-table-column>
            <el-table-column id="ce46f2" class="u5UTv34" prop="country" label="Country" show-overflow-tooltip :width="tableColumnWidth.colum2">
            </el-table-column>
            <el-table-column id="cf573e" class="uU8BOPC" prop="location" label="Location" show-overflow-tooltip :width="tableColumnWidth.colum3">
            </el-table-column>
            <el-table-column id="c82159" class="uTAgXFp" prop="type" label="Type" show-overflow-tooltip :width="tableColumnWidth.colum4" >
            </el-table-column>
            <el-table-column id="ca891b" class="uupj8Dl" prop="shares" label="Shares(%)" show-overflow-tooltip :width="tableColumnWidth.colum5" align="right">
            </el-table-column>
            <el-table-column id="cc0cd9" class="uvTqQIk" prop="relations" label="Relations"  show-overflow-tooltip :width="tableColumnWidth.colum6">
            </el-table-column>
          </el-table>
          <div id="cf4490" class="uZgkgLm show_AllHeight" v-if="this.pageTwo.total>this.pageTwo.pageSize">
            <span id="c938f1" class="u144f9 ufuLtku show_All" @click="ShowAllTwo()" >Show All </span>
          </div>
        </div>
        <!-- 图部分 -->
        <div v-show="activeIcon2 == 'computer2'" class="echartsBtnspiv">
          <div class="echartsBtns">
            <el-button icon="el-icon-plus" :disabled="level2 >= 3" @click="changeLevel2('plus')" />
            <el-button icon="el-icon-minus" :disabled="level2 <= 1" @click="changeLevel2('minus')" />
          </div>
          <div id="g08e9f">
            <div id="idsLwvVN" class="ulDmLg9" alt ref="ownership2"></div>
          </div>
        </div>
      </div>
      <!-- <PermissionDialog></PermissionDialog> -->
      <ReLogin :reLoginPop="reLoginVis"></ReLogin>
    </div>
  </div>
  
  <!--srcviewscompanydetailsBackgroundOwnershipvuehtmlEnd-->
</template>

 
<script>
import {
  getOwnership,
  getCompanyInvestment,
  getShareholderSummary,
  getCompanyCharttwo,
  getCompanyInvestmentChart,
  getPartPrivilege
} from "@/api/companyApi";
import PermissionDialog from '@/components/power/PermissionDialog.vue';
import ReLogin from "../../../../components/ReLogin.vue";
// interfaceCode
//importStart

import { ownershipData } from "../../../../assets/data/ownership.js";
import { formatterTxt, formatterTxtTips, formatList } from "./utils";
//importEnd

export default {
  name: "",
  props: {
    // srcviewscompanydetailsBackgroundOwnershipvuePropsStart
    // srcviewscompanydetailsBackgroundOwnershipvuePropsEnd
  },
  mounted() {
    this.getOwnershiplist();
    this.getPartPrivilege()
    this.getInvestmentList();
    this.echartslist();
    this.echartslisttwo()

    
    // defaultLoad
    // srcviewscompanydetailsBackgroundOwnershipvueMountedStart

    // srcviewscompanydetailsBackgroundOwnershipvueMountedEnd
  },
  created(){
    let screenWidth = window.innerWidth//获取屏幕宽度
    if(screenWidth <= 1441){
      this.tableColumnWidth = { // 表格不支持rem单位，这块只能手动转rem为px
        colum1:this.tableColumnWidth.colum1/1.5,
        colum2: this.tableColumnWidth.colum2/1.5,
        colum3: this.tableColumnWidth.colum3/1.5,
        colum4: this.tableColumnWidth.colum4/1.5,
        colum5: this.tableColumnWidth.colum5/1.5,
        colum6: this.tableColumnWidth.colum6/1.5,
      }
    }
    this.getShareholderSummaryList();

  },
  components: {
        PermissionDialog,
        ReLogin,
    },

  data() {
    return {
      tableColumnWidth:{
          colum1: 0,
          colum2: 220,
          colum3: 320,
          colum4: 210,
          colum5: 220,
          colum6: 200,
      },
      reLoginVis: false,
      chartData: null,

      aaid: "",
      echartsdate: [],
      powerArr:[],
      echartsdateTwo:[],
      level: 1,
      level1: 1,
      level2:1,
      level3:1,
      ownershipEchart: "",
      // ownershipEchart2
      ownershipEchart2: "",
      // maxMap
      maxMap: 2,
      // minMap
      minMap: 0.4,
      // activeIcon
      activeIcon: "table1",
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      pageTwo: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      comIcon1: "image://" + require("@/assets/img/echarts/comIcon1.png"),
      comIcon2: "image://" + require("@/assets/img/echarts/WechatIMG31.png"),
      comIcon3: "image://" + require("@/assets/img/echarts/comIcon3.png"),
      comIcon4: "image://" + require("@/assets/img/echarts/comIcon4.png"),
      comIcon5: "image://" + require("@/assets/img/echarts/comIcon5.png"),
      // tableData
      tableData: [],
      Investment: [],
      // activeIcon2
      activeIcon2: "table2",
      // srcviewscompanydetailsBackgroundOwnershipvueDataEnd
    };
  },
  methods: {
    powerEcharts(val){
      if(this.powerArr[1]?.hasPrivilege){
        if(val==1){
      this.showOwnershipEcahrt()

      }else{
        this.showOwnershipEcahrt2()
      }
      }else{
        this.$bus.$emit('show-permission-dialog', '您没有权限访问该功能！');
      }
     
    },
    getPartPrivilege(){
      getPartPrivilege({
        moduleId:this.$route.query.idpower
      }).then(res=>{
        this.powerArr[0]=(res.data.filter(item=>item.id=='405'))[0]
        this.powerArr[1]=(res.data.filter(item=>item.id=='406'))[0]
      })
      
    },
    add(list) {
      list.forEach(async (item) => {
        if (!item.children.length) {
          if (item.code) {
            const params = {
              id3a: item.code,
              page: "1",
              pageSize: "15",
            };
            const res = await getCompanyCharttwo(params);
            let ecahrtsdata = res;
            ecahrtsdata = JSON.parse(
              JSON.stringify(ecahrtsdata).replace(/subsetList/g, "children")
            );
            if (ecahrtsdata && ecahrtsdata.code) {
              this.loading = false;
              this.getList(
                this.echartsdate.children,
                item.code,
                ecahrtsdata.data.children
              );
              this.setEchartList();
              this.$nextTick(() => this.showOwnershipEcahrt());
            } else {
            }
          }
        } else {
          this.add(item.children);
        }
      });
    },
    changeLevel(type) {
      if (type === "plus") {
        this.level++;
        this.level1++;
        this.level1 > 4 ? (this.level1 = 4) : this.level11;
        
        // 点加号按钮寻找当前数组的最外层children
        let that = this;
        if (this.level >= 2) {
        
          if (this.level1 < 4) {
            that.add(that.echartsdate.children);
          }
        }
      }

      if (type === "minus") this.level--;
      this.$nextTick(() => this.showOwnershipEcahrt());
    },
    // 递归获取数组
    getList(list, code, newVal) {
      list.forEach((item, index) => {
        if (list[index].code === code) {
          list[index].children = newVal;
        } else if (list[index].children && list[index].children.length > 0) {
          this.getList(list[index].children, code, newVal);
        }
      });
    },
    async echartslist() {
      const params = {
        id3a: this.$route.query.id3a,
        page: "1",
        pageSize: "15",
      };
      const res = await getCompanyCharttwo(params);
      const ecahrtsdata = res;
      if (ecahrtsdata && ecahrtsdata.code) {
        this.echartsdate = ecahrtsdata.data;
        this.setEchartList();
        // this.$nextTick(() => this.showOwnershipEcahrt());
      } else {
        this.loading = false;
      }
    },
    setEchartList(echartsdate = this.echartsdate) {
      const echartData = echartsdate.subsetList || []; // 股东list

      const data = {
        name: this.echartsdate.name,
        nameEn: this.echartsdate.nameEn,
        code: this.companyAaaid,
        children: [],
      };
      const childrenList = formatList(
        echartData,
        this.companyAaaid,
        this.companyAaaid
      );
      data.children = childrenList;
      if (childrenList && childrenList.length > 0) {
        this.getLevel2Children(data, this.companyAaaid);
      } else {
        this.echartData = data;
      }
    },
    getLevel2Children(vals = data, aaaid) {
      const echartData = vals;

      echartData.children.map((item, index) => {
        if (item.isMax == aaaid) {
          const echartData = item.subsetList || [];
          const subsetList = formatList(
            echartData,
            this.companyAaaid,
            this.companyAaaid
          );
          item.subsetList = subsetList;
        }
      });
      this.echartsdate = echartData;
    },
    getShareholderSummaryList() {
      getShareholderSummary({ id3a: this.$route.query.id3a }).then((res) => {
        if (res.code == 200) {
          let manManagementSummary = [
            {
              name: "Ultimate Beneficiary",
              id: "Ultimate Beneficiary",
              num: res.data.ultimateBeneficiary,
            },
            {
              name: "Controlling Shareholder",
              id: "Controlling Shareholder",
              num: res.data.controllingCount,
            },
            {
              name: "Participations",
              id: "Participations",
              num: res.data.participation,
            },
            {
              name: "Involving Individuals",
              id: "Involving Individual",
              num: res.data.individualCount,
            },
            {
              name: "Involving Enterprises",
              id: "Involving Enterprises",
              num: res.data.enterpriseCount,
            },
          ];
          this.$emit("UpdatamarkTotalList", manManagementSummary);
        }
      });
    },
    ShowAllTwo(){
      if(this.pageTwo.total>this.pageTwo.pageSize){
      this.pageTwo.pageSize=this.pageTwo.pageSize+10
      this.getInvestmentList();

      }
    },
    ShowAll() {
     
      if(this.page.total>this.page.pageSize){
      this.page.pageSize=this.page.pageSize+10
      this.getOwnershiplist();

      }
      
    },

    getOwnershiplist() {
      const id3a = this.$route.query.id3a;
      getOwnership({
        id3a: id3a,
        page: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((Res) => {
        this.tableData = Res.data.rows;
        this.page.total=Res.data.total
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });
    },
    getInvestmentList() {
      const id3a = this.$route.query.id3a;
      getCompanyInvestment({
        id3a: id3a,
        page: this.pageTwo.pageNo,
        pageSize: this.pageTwo.pageSize,
      }).then((Res) => {
        this.Investment = Res.data.rows;
        this.pageTwo.total=Res.data.total
      });
    },
    roamMap(flag, ownership) {
      const currentZoom = ownership.getOption().series[0].zoom;
      let increaseAmplitude = 1.2;

      if (flag === 1) {
        increaseAmplitude = 0.8;
      }

      ownership.setOption({
        series: [
          {
            zoom: currentZoom * increaseAmplitude,
          },
        ],
      });
    }, 
    async showOwnershipEcahrt() {
      
      this.activeIcon = "computer1";
      await this.$nextTick();

      if (!this.ownershipEchart) {
        // debugger;
        this.ownershipEchart = this.$echarts.init(this.$refs.trendChart);
        await this.$nextTick();
      }
      const that = this;
      let leval = that.level;
      const initialTreeDepth = leval;
      that.echartsdate = JSON.parse(
        JSON.stringify(that.echartsdate).replace(/subsetList/g, "children")
      );
      let data = that.echartsdate;
      const symbol = (data) => {
        if (`${data.invtypecode}` === "3") {
          // 外国公司
          return this.comIcon3;
        }
        if (`${data.invtypecode}` === "5") {
          // 国营企业
          return this.comIcon5;
        }

        if (`${data.code}` === this.$route.query.id3a) {
          return this.comIcon4;
        }
        if (data.type == "2") {
          // 个人
          return this.comIcon2;
        }
        return this.comIcon1;
      };
      const option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          fontSize: 12,
          formatter: (params) => formatterTxt(params),
        },
        series: [
          {
            type: "tree",
            data: [data],
            top: "18%",
            bottom: "14%",
            // roam: true,
            clickable: true,

            layout: "radial",
            symbol: (value, param) => symbol(param.data),
            symbolSize: 30,
            initialTreeDepth,
            animationDurationUpdate: 750,
            emphasis: {
              focus: "ancestor",
            },
            orient: "TB",
            label: {
              fontSize: 9,
              formatter: (params) => formatterTxt(params),
              rotate: 360,
            },
            leaves: {
              label: {
                fontSize: 9,
                formatter: (params) => formatterTxt(params),
                rotate: 360,
              },
            },
          },
        ],
      };

      this.ownershipEchart.setOption(option);
    },


// Investment


    async showOwnershipEcahrt2() {
      this.activeIcon2 = "computer2";
      await this.$nextTick();

      if (!this.ownershipEchart2) {
        // debugger;
        this.ownershipEchart2 = this.$echarts.init(this.$refs.ownership2);
        await this.$nextTick();
      }
      const that = this;
      let leval = that.level2;
      const initialTreeDepth = leval;
      that.echartsdateTwo = JSON.parse(
        JSON.stringify(that.echartsdateTwo).replace(/subsetList/g, "children")
      );
      let data = that.echartsdateTwo;
      const symbol = (data) => {
        if (`${data.invtypecode}` === "3") {
          // 外国公司
          return this.comIcon3;
        }
        if (`${data.invtypecode}` === "5") {
          // 国营企业
          return this.comIcon5;
        }

        if (`${data.code}` === this.$route.query.id3a) {
          return this.comIcon4;
        }
        if (data.type == "2") {
          // 个人
          return this.comIcon2;
        }
        return this.comIcon1;
      };
      const option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          fontSize: 12,
          formatter: (params) => formatterTxt(params),
        },
        series: [
          {
            type: "tree",
            data: [data],
            top: "18%",
            bottom: "14%",
            // roam: true,
            clickable: true,

            layout: "radial",
            symbol: (value, param) => symbol(param.data),
            symbolSize: 30,
            initialTreeDepth,
            animationDurationUpdate: 750,
            emphasis: {
              focus: "ancestor",
            },
            orient: "TB",
            label: {
              fontSize: 9,
              formatter: (params) => formatterTxt(params),
              rotate: 360,
            },
            leaves: {
              label: {
                fontSize: 9,
                formatter: (params) => formatterTxt(params),
                rotate: 360,
              },
            },
          },
        ],
      };

      this.ownershipEchart2.setOption(option);
    },
    async echartslisttwo() {
      const params = {
        id3a: this.$route.query.id3a,
        page: "1",
        pageSize: "15",
      };
      const res = await getCompanyInvestmentChart(params);
      const ecahrtsdata = res;
      if (ecahrtsdata && ecahrtsdata.code) {
        this.echartsdateTwo = ecahrtsdata.data;
        
        this.setEchartListtwo();
        // this.$nextTick(() => this.showOwnershipEcahrt2());
      } else {
        this.loading = false;
      }
    },
  
    setEchartListtwo(echartsdate = this.echartsdateTwo) {
      
      const echartData = echartsdate.subsetList || []; // 股东list
   
      const data = {
        name: this.echartsdateTwo.name,
        nameEn: this.echartsdateTwo.nameEn,
        code: this.companyAaaid,
        children: [],
      };
      const childrenList = formatList(
        echartData,
        this.companyAaaid,
        this.companyAaaid
      );
      data.children = childrenList;
      if (childrenList && childrenList.length > 0) {
        this.getLevel2ChildrenTwo(data, this.companyAaaid);
      } else {
   
        
        this.echartDataTwo = data;
      }
    },
    getLevel2ChildrenTwo(vals = data, aaaid) {
      const echartData = vals;

      echartData.children.map((item, index) => {
        if (item.isMax == aaaid) {
          const echartData = item.subsetList || [];
          const subsetList = formatList(
            echartData,
            this.companyAaaid,
            this.companyAaaid
          );
          item.subsetList = subsetList;
        }
      });
      this.echartsdateTwo = echartData;
    },
    changeLevel2(type) {
      
      if (type === "plus") {
        this.level2++;
        this.level3++;
        this.level3 > 4 ? (this.level3 = 4) : this.level11;
     ;
        // 点加号按钮寻找当前数组的最外层children
        let that = this;
        if (this.level2 >= 2) {
          if (this.level3 < 4) {
            
            that.add2(that.echartsdateTwo.children);
          }
        }
      }

      if (type === "minus") this.level2--;
      this.$nextTick(() => this.showOwnershipEcahrt2());
    },
    add2(list) {
      list.forEach(async (item) => {
        if (!item.children.length) {
          if (item.code) {
            const params = {
              id3a: item.code,
              page: "1",
              pageSize: "15",
            };
            const res = await getCompanyInvestmentChart(params);
            let ecahrtsdata = res;
            ecahrtsdata = JSON.parse(
              JSON.stringify(ecahrtsdata).replace(/subsetList/g, "children")
            );
            if (ecahrtsdata && ecahrtsdata.code) {
              this.loading = false;
              this.getList(
                this.echartsdateTwo.children,
                item.code,
                ecahrtsdata.data.children
              );
              this.setEchartListtwo();
              this.$nextTick(() => this.showOwnershipEcahrt2());
            } else {
            }
          }
        } else {
          
          this.add2(item.children);
        }
      });
    },
    setid(row) {

   
let companyCountryEn = "";

let routeData = this.$router.resolve({
  path:
    "/index/company/detals/" +
    row.id3a +
    "/background/snapshot?id3a=" +
    row.id3a +
    "&companyCountry=" +
    ''+
    "&companyCountryEn=" +
    companyCountryEn,
});

window.open(routeData.href, "_blank");

},
    // 
  },
  computed: {},
};
</script>
<style>
.srcviewscompanydetailsBackgroundOwnershipvuecssStart {}

.u68g3Fw {}

.u97906 {
  background-color: rgb(255, 255, 255);
}

.u57fd1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.85rem;
  padding-right: 2.85rem;
  padding-top: 1.4285714285714286rem;
}

.u5e22d {
  font-size: 2.142857142857143rem;
  /* font-weight: bold; */
  font-family: 'DMSans Bold';
}

.u41151 {
  display: flex;
  width: 10rem;
  border-radius: 1.7857142857142858rem;
  background: #f5f5f5;
  padding: 0.21428571428571427rem;
}

.ub7903 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 3.142857142857143rem;
  border-radius: 1.5714285714285714rem;
}

.u72482 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 3.142857142857143rem;
  border-radius: 1.5714285714285714rem;
}

.uM2fl1R {
  margin-left: 2.85em;
  margin-top: 1.4285714285714286rem;
  margin-right: 2.85rem;
}

.uvnONjL {
  background-color: #ccc;
}

.uZgkgLm {
  height: 4.571428571428571rem;
  line-height: 4.571428571428571rem;
 
  color: #1290c9;
  text-align: center;
}
.u144f9{
  font-size:1.4285714285714286rem ;
}
.echartsBtns {
  position: absolute;
  top: 2.142857142857143rem;
  left: 2.142857142857143rem;
  display: flex;
  flex-direction: column;
  z-index: 999999;
}

.uXdHX63 {
  display: flex;
  justify-content: center;
  /* position:relative;
}
		.u1fb61{/* left:3.5714285714285716em; */
  /* position:absolute; 
        top;*/
}

.u13926 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  margin-left: 2.857142857142857rem;
  box-shadow: 0rem 0rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.2857142857142857rem;
}

.u95696 {
  margin-left: 2.857142857142857rem;
  margin-top: 0.7142857142857143rem;
}

.u44WfUA {
  width: 0;
  height: 56.857142857142854rem;
  flex: auto;
}

.u702a4 {
  width: 2.5rem;
  height: 2.5rem;
}

.ulDmLg9 {
  height: 56.857142857142854rem;
}

.uqxpbOh:hover {
  cursor: pointer;
}

.u2XrWW1:hover {
  cursor: pointer;
}

.u702a4:hover {
  cursor: pointer;
}

.uManXh9:hover {
  cursor: pointer;
}

.ecahrtstu {
  position: relative;
  display: flex;
  justify-content: center;
  width: 85.71428571428571rem;

  background: #ffffff;
}

.trendEchartBox1 {
  width: 85.71428571428571rem;
  height: 44.57142857142857rem;
}

.srcviewscompanydetailsBackgroundOwnershipvuecssEnd {}

.echartsBtnspiv {
  position: relative;
}
.gaosimohu{
  filter: blur(0.35714285714285715em);
    background: #CCCCCC;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 99999999;
    display: none;
}
#cf7af3{
  /* filter: blur(0.35714285714285715em); */
  position: relative;
  /* display: none; */
}
.el-table::before{
  background-color: transparent !important;
}

.u68g3Fw .el-table__row td:nth-child(5),
.u68g3Fw .has-gutter th:nth-child(5) {
  padding-right: 2.857142857142857rem !important;
}

.echartsBtns .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
  margin-left: 0;
  margin-top: 0.7142857142857143rem;
}

.el-button:focus, .el-button:hover {
  color: #1290c9;
  border: none;
  background: #ffffff;
}

.el-button {
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  padding: 0;
  background: #ffffff;
  border: none;
  box-shadow: 0rem 0rem 0.2857142857142857rem 0rem rgba(0,0,0,0.1);
  border-radius: 0.2857142857142857rem;
}

.el-icon-plus {
  font-size: 1.1428571428571428rem;
  color: #1A2332;
}

.el-icon-minus {
  font-size: 1.1428571428571428rem;
  color: #1A2332;
}

.el-button:focus .el-icon-plus, .el-button:hover .el-icon-plus,
.el-button:focus .el-icon-minus, .el-button:hover .el-icon-minus {
  color: #1290c9;
}

.el-button.is-disabled:focus .el-icon-plus, .el-button.is-disabled:hover .el-icon-plus,
.el-button.is-disabled:focus .el-icon-minus , .el-button.is-disabled:hover .el-icon-minus {
  color: #1A2332;
}

@media screen and (max-width: 1441px) {
 
}
</style>